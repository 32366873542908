

html, body {
  height: 100%;
}

body {
  background-image: linear-gradient(135deg,#6c33da,#af4dfd);
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
